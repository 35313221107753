<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar style="text-align: center">
        <ion-buttons slot="start">
          <ion-button disabled>
            <ion-icon></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="primary">
          <ion-button @click="closeModal()">
            <ion-icon
              slot="icon-only"
              style="color: grey"
              :icon="closeOutline"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
        <img src="/assets/logo.png" style="height: 50px" />
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <h1 style="color: black">{{ $t("titlePage.passwordReset") }}</h1>
      <div>
        <v-form @submit="sendResetPasswordEmail">
          <ion-item lines="none" class="fieldItem">
            <ion-icon
              :icon="mailOutline"
              slot="start"
              style="color: #999"
            ></ion-icon>
            <v-field
              name="email"
              v-slot="{ field }"
              rules="required|email|max:50"
              :validateOnInput="aggressiveValidation"
              :validateOnBlur="false"
            >
              <input
                @change="aggressiveValidation = true"
                :value="field.value"
                v-bind="field"
                name="email"
                class="inputField"
                placeholder="E-mail"
                type="email"
              />
            </v-field>
          </ion-item>
          <p class="error">
            <v-error-message name="email" />
          </p>
          <ion-button
            mode="ios"
            type="submit"
            expand="block"
            shape="round"
            color="primary"
            style="margin-top: 15px; height: 3rem"
          >
            <ion-spinner v-if="ongoingProcess"></ion-spinner>
            <span v-else>{{ $t("buttons.continue") }}</span>
          </ion-button>
        </v-form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonContent,
  IonIcon,
  IonButton,
  IonButtons,
  IonSpinner,
  IonToolbar,
  IonItem,
  IonHeader,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { closeOutline, checkmarkOutline, mailOutline } from "ionicons/icons";

import * as V from "vee-validate/dist/vee-validate";
import { defineRule } from "vee-validate/dist/vee-validate";
import { required, email } from "@vee-validate/rules";
import { useI18n } from "vue-i18n";

import { fbFunctions } from "@/firebase";
import { openToast } from "@/general/toasts";

export default defineComponent({
  name: "PasswordReset",
  components: {
    IonPage,
    IonContent,
    IonIcon,
    IonButton,
    IonButtons,
    IonToolbar,
    IonItem,
    IonSpinner,
    IonHeader,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });

    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    defineRule("required", required);
    defineRule("email", email);

    const aggressiveValidation = ref<boolean>(false);
    const mailSentFlag = ref(false);
    const ongoingProcess = ref(false);

    const sendResetPasswordEmail = (values: any, actions: any) => {
      ongoingProcess.value = true;
      const sendResetEmailCF = fbFunctions.httpsCallable(
        "sendPasswordResetCustomEmail"
      );

      sendResetEmailCF({ email: values.email })
        .then((response) => {
          if (response.data.result == "ok") {
            openToast("passwordResetSuccess", true, 4000);
            closeModal();
          } else {
            if (response.data.code == "auth/invalid-email") {
              actions.setFieldError("email", t("authValidation.invalidEmail"));
            } else if (response.data.code == "auth/email-not-found") {
              actions.setFieldError("email", t("authValidation.userNotFound"));
            } else if (response.data.code == "missingEmail") {
              actions.setFieldError("email", t("validation.required"));
            } else if (response.data.code == "auth/network-request-failed") {
              openToast("networkConnectionFailed", false, 5000);
            } else {
              openToast("passwordResetFail", false, 10000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          openToast("passwordResetFail", false, 10000);
        })
        .finally(() => {
          ongoingProcess.value = false;
        });

      // fbAuth.languageCode = locale.value;
      // ongoingProcess.value = true;
      // fbAuth
      //   .sendPasswordResetEmail(values.email)
      //   .then(() => {
      //     mailSentFlag.value = true;
      //   })
      //   .catch((error) => {
      //     if (error.code == "auth/invalid-email") {
      //       actions.setFieldError("email", t("authValidation.invalidEmail"));
      //     } else if (error.code == "auth/user-not-found") {
      //       actions.setFieldError("email", t("authValidation.userNotFound"));
      //     } else if (error.code == "auth/user-disabled") {
      //       actions.setFieldError("email", t("authValidation.userDisabled"));
      //     } else {
      //       actions.setFieldError("email", "unkown");
      //     }
      //   })
      //   .finally(() => {
      //     ongoingProcess.value = false;
      //   });
    };

    return {
      ongoingProcess,
      aggressiveValidation,
      required,
      email,
      sendResetPasswordEmail,
      mailSentFlag,
      mailOutline,
      closeModal,
      closeOutline,
      checkmarkOutline,
    };
  },
});
</script>

<style scoped>
.error {
  display: block;
  margin: 5px 1.5rem;
  font-weight: light;
  font-size: smaller;
  color: rgb(228, 2, 2);
}

ion-item.fieldItem {
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-top: 5px;
  height: 3rem;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

ion-item.fieldItem:hover {
  border-color: #808080;
}

ion-item {
  --ion-item-background: white;
  --ion-text-color: black;
}

.inputField {
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
}

ion-content {
  --background: white !important;
}

ion-toolbar {
  --background: white !important;
}
</style>