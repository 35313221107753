import { fbAuth, fbDB } from "../firebase";
import { ref } from "vue";
import { toastController } from "@ionic/core";

// import { clearContacts } from "@/components/contacts";
// import { clearSuggestions } from "@/components/suggestions";
import { clearCriteria } from "@/components/criteria";


export const user = ref<any>();
// TODO overwrites by page refresh

export const checkRegistrationProcessFlag = ref<boolean>(true);
export const registrationProcess = ref<{ base: boolean; criteria: boolean; profile: boolean }>({ base: false, criteria: false, profile: false });

// fbAuth.onAuthStateChanged((u) => {
//   user.value = u;
// });


export async function logout() {
  try {

    let topToast = await toastController.getTop();
    while (topToast != undefined) {
      await topToast.dismiss();
      topToast = await toastController.getTop();
    }
    await fbAuth.signOut();
    clearCriteria();
    user.value = null; // already done in onAuthStateChanged observer
  } catch (err) {
    console.log("from logout()");
    console.log(err);
  }
}

export async function signupClassic(data: any) {
  let userCred;

  try {
    userCred = await fbAuth.createUserWithEmailAndPassword(data.email, data.password);
    if (userCred.user != null) {
      user.value = userCred.user;
    } else {
      return { result: 'authFail', code: 'unknown' };
    }
  } catch (error) {
    return { result: 'authFail', code: error.code };
  }

  try {
    await userCred.user.updateProfile({ displayName: data.name });
    const updates: { [key: string]: any } = {};
    updates[`users/${user.value.uid}/finder/`] = { sex: data.sex, year: data.year };
    updates[`users/${user.value.uid}/userInfo/registration`] = { base: true, criteria: false, profile: false };
    await fbDB.ref().update(updates);
    return { result: 'success' };
  } catch (err) {
    console.log(err.code);
    return { result: 'dbFail' }
  }
}


export async function loginClassic(data: { email: string; password: string }) {
  try {
    const userCred = await fbAuth.signInWithEmailAndPassword(
      data.email,
      data.password
    );
    if (userCred.user != null) {
      user.value = userCred.user;

      const regStepSnap = await fbDB.ref(`/users/${user.value.uid}/userInfo/registration`).once('value');
      const regStep = regStepSnap.val();
      if (regStep != null) {
        registrationProcess.value["base"] = regStep.base || false;
        registrationProcess.value["criteria"] = regStep.criteria || false;
        registrationProcess.value["profile"] = regStep.profile || false;
      }

      if (regStep == null || registrationProcess.value["base"] == false) {
        return { result: "success", code: "registrationBase" }; // TODO regBase
      } else if (registrationProcess.value["criteria"] == false) {
        return { result: "success", code: "registrationCriteria" };
      } else if (registrationProcess.value["profile"] == false) {
        return { result: "success", code: "registrationProfile" };
      } else if (registrationProcess.value["criteria"] && registrationProcess.value["profile"]) {
        return { result: "success", code: "ok" };
      } else {
        return { result: "success", code: "registrationBase" };
      }
    } else {
      return { result: 'authFail', code: 'unknown' };
    }

  } catch (error) {
    return { result: 'authFail', code: error.code };
  }
}

