import { toastController } from "@ionic/vue";
import { i18n } from "@/main";

export async function openToast(code: string, success: boolean, duration?: number) {
  const toast = await toastController.create({
    message:  i18n.global.t(((success)? "toastSuccess.": "toastFail.") + code),
    color: (success) ? "success" :"danger",
    duration: (duration) ? duration : 0,
    buttons: [
      {
        icon: "close",
        role: "cancel",
        handler: () => {
          toast.dismiss();
        },
      },
    ],
  });
  return toast.present();
}