export const firebaseConfigProd = {
  apiKey: "AIzaSyA5LE16k2bn7dhmDoZVsdsJkeCgS6STQBk",
  authDomain: "laska-club-prod.firebaseapp.com",
  databaseURL: "https://laska-club-prod-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "laska-club-prod",
  storageBucket: "laska-club-prod.appspot.com",
  messagingSenderId: "797614427899",
  appId: "1:797614427899:web:2b480421e0e2584e076a5e",
  measurementId: "G-GHZT4VF0WJ"
}

export const firebaseConfigDev = {
  apiKey: "AIzaSyBdpDRZPN5bAarY4e6nRiRwx2PAKS4NUhk",
  authDomain: "laska-club-dev.firebaseapp.com",
  databaseURL: "https://laska-club-dev-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "laska-club-dev",
  storageBucket: "laska-club-dev.appspot.com",
  messagingSenderId: "873233275623",
  appId: "1:873233275623:web:e55d236c446e730cf3a042"
}