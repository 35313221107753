import { i18n } from "@/main";
import { alertController } from "@ionic/core";
import { setLocale } from "@vee-validate/i18n";

import { user, registrationProcess } from "@/auth";
import { ref } from "vue";
import { fbDB } from "@/firebase";


export async function checkRegistrationProcess() {
  const regStepSnap = await fbDB.ref(`/users/${user.value.uid}/userInfo/registration`).once('value');
  const regStep = regStepSnap.val();
  console.log("from checking");
  console.log(regStep);
  if (regStep != null) {
    registrationProcess.value = regStep;
  }
}

export const darkModeEnabled = ref<boolean>();

export function setDarkMode() {
  if (localStorage.darkMode == 'true') {
    darkModeEnabled.value = true;
    document.body?.classList.add("dark");
  } else {
    darkModeEnabled.value = false;
    document.body?.classList.remove("dark");
  }
}

export function enableDarkMode() {
  localStorage.darkMode = true;
  darkModeEnabled.value = true;
  document.body?.classList.add("dark");
}

export function disableDarkMode() {
  localStorage.darkMode = false;
  darkModeEnabled.value = false;
  document.body?.classList.remove("dark");
}


export async function presentLanguageSelector() {
  // const { t, locale } = useI18n({ useScope: "global" });
  const alert = await alertController.create({
    cssClass: "my-custom-class",
    header: i18n.global.t('myAccount.language'),
    inputs: [
      {
        type: "radio",
        label: "Slovenčina",
        value: "sk",
        checked: i18n.global.locale == "sk" ? true : false,
      },
      {
        type: "radio",
        label: "Angličtina",
        value: "gb",
        checked: i18n.global.locale == "gb" ? true : false,
      }
    ],
    buttons: [
      {
        text: i18n.global.t('buttons.close'),
        role: "cancel",
        cssClass: "secondary",
        handler: () => {
          console.log("Confirm Cancel");
        },
      },
      {
        text: i18n.global.t('buttons.save'),
        handler: (value) => {
          if (value == "gb") {
            setLocale("en");
          } else {
            setLocale(value);
          }
          i18n.global.locale = value;
          localStorage.language = value;
        },
      },
    ],
  });
  return alert.present();
}
