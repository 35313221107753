<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="ios">
        <ion-buttons slot="end">
          <ion-button @click="closeModal()" color="primary">
            {{ $t("buttons.done") }}
          </ion-button>
        </ion-buttons>
        <ion-title>
          <img src="/assets/logo.png" style="height: 30px" />
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card style="margin: 0px; border-radius: 0px; box-shadow: none">
        <ion-card-header>
          <ion-card-subtitle>{{
            $t("criteria.education.education")
          }}</ion-card-subtitle>
          <ion-card-title>{{
            $t("criteria.education.question")
          }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-radio-group
              allow-empty-selection
              v-model="myCriteria.education"
            >
              <ion-item v-for="(item, index) in educationList" :key="index">
                <ion-label>{{
                  $t("profile.education.options." + item.code)
                }}</ion-label>
                <ion-radio
                  slot="start"
                  color="primary"
                  :value="item.code"
                ></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonRadio,
  IonRadioGroup,
  IonPage,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonTitle,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline } from "ionicons/icons";
import { myCriteria } from "@/components/criteria";
import jsonData from "@/assets/criteriaData.json";

export default defineComponent({
  name: "Education",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonRadio,
    IonRadioGroup,
    IonPage,
    IonList,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonTitle,
  },
  props: {},
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };
    const educationList = jsonData.education;

    return {
      educationList,
      closeModal,
      checkmarkOutline,
      myCriteria,
    };
  },
});
</script>

<style scoped>
ion-item {
  --background: inherit;
}
</style>