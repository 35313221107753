
import {
  IonPage,
  IonContent,
  IonIcon,
  IonButton,
  IonButtons,
  IonSpinner,
  IonToolbar,
  IonItem,
  IonHeader,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { closeOutline, checkmarkOutline, mailOutline } from "ionicons/icons";

import * as V from "vee-validate/dist/vee-validate";
import { defineRule } from "vee-validate/dist/vee-validate";
import { required, email } from "@vee-validate/rules";
import { useI18n } from "vue-i18n";

import { fbFunctions } from "@/firebase";
import { openToast } from "@/general/toasts";

export default defineComponent({
  name: "PasswordReset",
  components: {
    IonPage,
    IonContent,
    IonIcon,
    IonButton,
    IonButtons,
    IonToolbar,
    IonItem,
    IonSpinner,
    IonHeader,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });

    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    defineRule("required", required);
    defineRule("email", email);

    const aggressiveValidation = ref<boolean>(false);
    const mailSentFlag = ref(false);
    const ongoingProcess = ref(false);

    const sendResetPasswordEmail = (values: any, actions: any) => {
      ongoingProcess.value = true;
      const sendResetEmailCF = fbFunctions.httpsCallable(
        "sendPasswordResetCustomEmail"
      );

      sendResetEmailCF({ email: values.email })
        .then((response) => {
          if (response.data.result == "ok") {
            openToast("passwordResetSuccess", true, 4000);
            closeModal();
          } else {
            if (response.data.code == "auth/invalid-email") {
              actions.setFieldError("email", t("authValidation.invalidEmail"));
            } else if (response.data.code == "auth/email-not-found") {
              actions.setFieldError("email", t("authValidation.userNotFound"));
            } else if (response.data.code == "missingEmail") {
              actions.setFieldError("email", t("validation.required"));
            } else if (response.data.code == "auth/network-request-failed") {
              openToast("networkConnectionFailed", false, 5000);
            } else {
              openToast("passwordResetFail", false, 10000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          openToast("passwordResetFail", false, 10000);
        })
        .finally(() => {
          ongoingProcess.value = false;
        });

      // fbAuth.languageCode = locale.value;
      // ongoingProcess.value = true;
      // fbAuth
      //   .sendPasswordResetEmail(values.email)
      //   .then(() => {
      //     mailSentFlag.value = true;
      //   })
      //   .catch((error) => {
      //     if (error.code == "auth/invalid-email") {
      //       actions.setFieldError("email", t("authValidation.invalidEmail"));
      //     } else if (error.code == "auth/user-not-found") {
      //       actions.setFieldError("email", t("authValidation.userNotFound"));
      //     } else if (error.code == "auth/user-disabled") {
      //       actions.setFieldError("email", t("authValidation.userDisabled"));
      //     } else {
      //       actions.setFieldError("email", "unkown");
      //     }
      //   })
      //   .finally(() => {
      //     ongoingProcess.value = false;
      //   });
    };

    return {
      ongoingProcess,
      aggressiveValidation,
      required,
      email,
      sendResetPasswordEmail,
      mailSentFlag,
      mailOutline,
      closeModal,
      closeOutline,
      checkmarkOutline,
    };
  },
});
