import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/Tabs.vue';
import LoginPage from '../views/LoginPage.vue';
import Registration from '../views/RegistrationPage.vue';


import { fbAuth } from "../firebase";

const routes: Array<RouteRecordRaw> = [
  {
    name: "login",
    path: '/login',
    component: LoginPage,
  },
  {
    name: "registration",
    path: '/registration/',
    component: Registration,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: '/registration/criteria',
      },
      {
        path: "base",
        name: "registrationBase",
        component: () => import("@/components/registration/RegBaseBackup.vue"),
        meta: {requiresAuth: true, requiresRegistration: true}
      },
      {
        path: 'criteria',
        name: 'registrationCriteria',
        component: () => import('@/components/registration/RegCriteria.vue'),
        meta: { requiresAuth: true, requiresRegistration: true },
      },
      {
        path: 'profile',
        name: 'registrationProfile',
        component: () => import('@/components/registration/RegProfile.vue'),
        meta: { requiresAuth: true, requiresRegistration: true },
      },
    ],
  },
  {
    path: '/laska',
    name: 'laska',
    redirect: '/my-account',
    // component: () => import('@/components/registration/RegDone.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/messages/:id',
    component: () => import('@/components/messages/TheChat.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/components/profile/MyProfile.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'profileEdit',
    path: '/profile-edit',
    component: () => import('@/components/profile/MyProfileEdit.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/',
    component: Tabs,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: '/messages',
        meta: { requiresAuth: true },
      },
      {
        name: 'messages',
        path: 'messages',
        component: () => import('@/views/MessagesTab.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'likes',
        component: () => import('@/views/LikesTab.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'finder',
        component: () => import('@/views/SuggestionsTab.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'contacts',
        component: () => import('@/views/ContactsTab.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: "my-account",
        path: 'my-account',
        component: () => import('@/views/MyAccountTab.vue'),
        meta: { requiresAuth: true },
      }
    ]
  },
  { name: "default", path: "/:pathMatch(.*)*", redirect: { name: 'my-account' } }
]

// import { RouterScrollBehavior } from 'vue-router';

// const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
//   return { left: 0, top: -100 };
// }

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior

})

router.beforeEach(async (to, from, next) => {
  // TODO registration incomplete guard
  if (to.meta.requiresAuth && !fbAuth.currentUser) {
    next({ name: 'login', query: { redirect: to.fullPath } },)

  } else if ((to.name == "login") && fbAuth.currentUser) {
    next({ name: "messages" });

  }
  // else if (to.fullPath.includes('registration') && !registrationProcess.value) {
  //   next({name: "messages"});

  // } else if (to.fullPath.includes('registration') && registrationProcess.value) {
  //   next();

  // } else if (registrationProcess.value) {
  //   next({path:`/registration/${registrationStep.value}`});
  // }
  else {
    next();
  }
})

export default router
