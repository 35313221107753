
import { arrowBackOutline, informationOutline } from "ionicons/icons";
import router from "@/router";

export default {
  name: "Registration",
  components: {},
  setup() {
    // ============================================================ //
    const goBack = () => {
      if (router.currentRoute.value.name == "registrationProfile") {
        router.replace({ name: "registrationCriteria" });
      } else if (router.currentRoute.value.name == "registrationFinish") {
        router.replace("/registration/profile");
      }
    };

    return {
      goBack,
      router,
      arrowBackOutline,
      informationOutline,
    };
  },
};
