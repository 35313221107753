import { ref } from "vue";
import { openToast } from "@/general/toasts";

import { fbDB, fbFunctions } from "@/firebase";
import { user } from "@/auth";
import { loadBaseInfoByUid } from "../profile/show";

const removeContactCF = fbFunctions.httpsCallable('removeContact');

/**
 * ===DATABASE===
 * Contacts list entry structure saved in database.
 * /users/{uid}/contacts/{contactUid}
 *  - new: true
 *  - blocked: false
 *  - timestamp: 132314102
 *  - name: "Janka"
 *  - messagesID: "1242134j12jg32441"
 */


/**
 * ===TEMPLATE===
 * Parsed contacts list structure to be used in template
 * 
 * {
 * "A": [
 *  {uid: "asdfhasghdfhgh3htr38u2dj", 
 *   new: true,
 *   blocked: false,
 *   messagesID: "asdf214i2j34asdf",
 *   name: "Janka",
 *   baseInfo: {}}
 * ],
 * "B": [],
 * ...
 * }
 */
export interface ContactsListInterface {
  A: { [key: string]: { [key: string]: Record<string, unknown> } };
  B: { [key: string]: { [key: string]: any } };
  C: { [key: string]: { [key: string]: any } };
  D: { [key: string]: { [key: string]: any } };
  E: { [key: string]: { [key: string]: any } };
  F: { [key: string]: { [key: string]: any } };
  G: { [key: string]: { [key: string]: any } };
  H: { [key: string]: { [key: string]: any } };
  I: { [key: string]: { [key: string]: any } };
  J: { [key: string]: { [key: string]: any } };
  K: { [key: string]: { [key: string]: any } };
  L: { [key: string]: { [key: string]: any } };
  M: { [key: string]: { [key: string]: any } };
  N: { [key: string]: { [key: string]: any } };
  O: { [key: string]: { [key: string]: any } };
  P: { [key: string]: { [key: string]: any } };
  R: { [key: string]: { [key: string]: any } };
  S: { [key: string]: { [key: string]: any } };
  T: { [key: string]: { [key: string]: any } };
  V: { [key: string]: { [key: string]: any } };
  U: { [key: string]: { [key: string]: any } };
  W: { [key: string]: { [key: string]: any } };
  X: { [key: string]: { [key: string]: any } };
  Y: { [key: string]: { [key: string]: any } };
  Z: { [key: string]: { [key: string]: any } };
  $: { [key: string]: { [key: string]: any } };
}

const defaultContactsList: ContactsListInterface = {
  A: {},
  B: {},
  C: {},
  D: {},
  E: {},
  F: {},
  G: {},
  H: {},
  I: {},
  J: {},
  K: {},
  L: {},
  M: {},
  N: {},
  O: {},
  P: {},
  R: {},
  S: {},
  T: {},
  V: {},
  U: {},
  W: {},
  X: {},
  Y: {},
  Z: {},
  $: {},
};

export const myContacts = ref<ContactsListInterface>(JSON.parse(JSON.stringify(defaultContactsList)));
export const contactsCount = ref<number>(0);
export const loadingMyContacts = ref<boolean>(true);

export function clearContacts() {
  myContacts.value = JSON.parse(JSON.stringify(defaultContactsList));
  contactsCount.value = 0;
}

export async function loadMyContacts() {
  clearContacts();
  loadingMyContacts.value = true;

  const contactsListRef = fbDB.ref("users/" + user.value.uid + "/contacts");
  contactsListRef.on('child_added', async (snap) => {
    if (snap.val() == null || snap.key == null) {
      return;
    }
    const partnerUid: string = snap.key;
    let contactData = snap.val();

    const { result, profile } = await loadBaseInfoByUid(partnerUid);
    if (result == 'fail' || !profile) {
      console.log("contact profile loading failed");
      // error occured 
      return;
    }

    let initialLetter: keyof ContactsListInterface;
    try {
      initialLetter = (profile.name[0]).toUpperCase();
    } catch (e) {
      initialLetter = '$';
    }
    contactData = { ...contactData, ...{ profile: profile } };
    myContacts.value[initialLetter][partnerUid] = contactData;
    contactsCount.value = contactsCount.value + 1; // TODO move to DB?
    loadingMyContacts.value = false;
  })

  contactsListRef.on('child_removed', snap => {
    if (snap.key == null) {
      return;
    }
    const contactUid = snap.key;

    let initialLetter: keyof ContactsListInterface;
    for (initialLetter in myContacts.value) {
      if (contactUid in myContacts.value[initialLetter]) {
        delete myContacts.value[initialLetter][contactUid];
        contactsCount.value = contactsCount.value - 1;
      }
    }
  });

  setTimeout(() => {
    loadingMyContacts.value = false;
  }, 1500);
}


export async function blockContact(uid: string, chatId: string) {
  try {
    const updates: any = {};
    updates[`/users/${user.value.uid}/contacts/${uid}/blocked`] = true;
    updates[`/users/${user.value.uid}/chats/${chatId}/blocked`] = true;
    await fbDB.ref().update(updates);

    let initialLetter: keyof ContactsListInterface;
    for (initialLetter in myContacts.value) {
      if (uid in myContacts.value[initialLetter]) {
        myContacts.value[initialLetter][uid]['blocked'] = true;
      }
    }
    openToast("contactBlockSuccess", true, 3000);
  } catch (err) {
    console.log(err);
    openToast("contactBlockFail", false);
  }
}

export async function unblockContact(uid: string, chatId: string) {
  let lastMessage = null;
  try {
    const lastMessageSnap = await fbDB.ref(`/chats/${chatId}/messages/`).limitToLast(1).once('value');
    if (lastMessageSnap.val() != null) {
      lastMessage = Object.entries(lastMessageSnap.val())[0][1];
    }
  } catch (error) {
    console.log(error);
    console.log("ERROR: failed to get last message during unblocking contact.")
  }

  try {
    const updates: any = {};
    updates[`/users/${user.value.uid}/contacts/${uid}/blocked`] = false;
    updates[`/users/${user.value.uid}/chats/${chatId}/blocked`] = false;
    if (lastMessage != null) {
      updates[`/users/${user.value.uid}/chats/${chatId}/lastMessage`] = lastMessage;
    }
    await fbDB.ref().update(updates);

    let initialLetter: keyof ContactsListInterface;
    for (initialLetter in myContacts.value) {
      if (uid in myContacts.value[initialLetter]) {
        myContacts.value[initialLetter][uid]['blocked'] = false;
      }
    }
    openToast("contactUnblockSuccess", true, 3000);
  } catch (err) {
    console.log(err);
    openToast("contactUnblockFail", false);
  }
  return;
}

export async function removeContact(uid: string, chatId: string) {
  try {
    const response = await removeContactCF({ uid: uid, chatId: chatId })
    if (response.data.result == "success") {
      openToast("contactDeleteSuccess", true, 3000);
      return "ok";
    } else {
      openToast("contactDeleteFail", false);
      return "fail";
    }
  } catch (err) {
    console.log(err);
    openToast("contactDeleteFail", false);
    return "fail";
  }
}