<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="ios">
        <ion-buttons slot="end">
          <ion-button @click="closeModal()" color="primary">
            {{$t("buttons.done")}}
          </ion-button>
        </ion-buttons>
        <ion-title>
         <img src="/assets/logo.png" style="height:30px;"/>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card
        style="margin: 0px; border-radius: 0px; box-shadow: none"
      >
        <ion-card-header>
          <ion-card-subtitle>{{$t("criteria.location.location")}}</ion-card-subtitle>
          <ion-card-title
            >{{ $t("criteria.location.question") }}</ion-card-title
          >
        </ion-card-header>
        <ion-card-content>
          <ion-item v-for="(loc, index) in locationsList" :key="index">
            <ion-checkbox
              id="loc.code"
              color="primary"
              slot="start"
              :value="loc.code"
              @update:modelValue="loc.isChecked = $event"
              :modelValue="loc.isChecked"
              @ionChange="checkboxChanged($event)"
            ></ion-checkbox>
            <ion-label> {{ $t("profile.location.options."+loc.code) }} </ion-label>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonTitle,
  IonCheckbox,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline } from "ionicons/icons";
import { myCriteria } from "@/components/criteria";
import jsonData from "@/assets/criteriaData.json";

import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Location",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonTitle,
    IonCheckbox,
  },
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };
    
    const locationsList = ref(jsonData.locations);
    
    locationsList.value.forEach((item) => {
       if (myCriteria.value.locations) {
        if ( myCriteria.value.locations?.[item.code] ) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
    });

    /** Add or remove language to/from criteria directory. */
    const checkboxChanged = (event: any) => {
      const locCode: string = event.detail.value;
      if (!myCriteria.value.locations) {
        myCriteria.value.locations = {}
      }

      if (event.detail.checked == false) {
        delete myCriteria.value.locations[locCode];
      } else if (event.detail.checked == true) {
        myCriteria.value.locations[locCode] = true;
      }
    };

    return {
      checkboxChanged,
      locationsList,
      closeModal,
      checkmarkOutline,
    };
  },
});
</script>

<style scoped>
ion-item {
  --background: inherit;
}
</style>