import firebase from "firebase/app";
import "firebase/analytics";

import "firebase/auth";
import "firebase/database";
import "firebase/messaging";
import "firebase/storage";
import "firebase/functions";
import "firebase/app-check";


/** FOR DEVELOPLMENT */
// import {firebaseConfigDev} from "@/firebaseConfigs"; 
// const firebaseConfig = firebaseConfigDev;

/** FOR PRODUCTION */
import {firebaseConfigProd} from "@/firebaseConfigs"; 
const firebaseConfig = firebaseConfigProd;


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
// utils
const fbAuth = firebase.auth()
const googleProvider = new firebase.auth.GoogleAuthProvider();
const fbDB = firebase.database()
const fbStorage = firebase.storage()
const fbFunctions = firebase.functions();
// const appCheck = firebase.appCheck();

// appCheck.activate("6LcsHmYdAAAAAC8IXm2ImpDCDRYnK4hXnyh-IjNL",true);

// appCheck.activate(
//   new firebase.appCheck.ReCaptchaEnterpriseProvider(
//     /* reCAPTCHA Enterprise site key */
//   ),
//   true // Set to true to allow auto-refresh.
// );


/** RUN on EMULATORS 
 *  only for DEVELOPMENT
*/
// if(location.hostname == 'localhost') {
//   console.log("EMULATORS");
//   fbAuth.useEmulator("http://localhost:9099");
//   fbDB.useEmulator("localhost", 9000);
//   fbFunctions.useEmulator("localhost", 5001);
//   fbStorage.useEmulator("localhost", 9199);
// }


/** FCM */
// try {
//   const fbMessaging = firebase.messaging();
//   if(firebase.messaging.isSupported()) {
//     console.log("FCM is supported!");
//     fbMessaging.getToken()
//     .then((token) => {
//       if(token) {
//         console.log(token);
//       } else {
//         console.log('No registration token available. Request permission to generate one.');
//       }
//     })
//     .catch((err) => {
//       console.log("error: " + err);
//     })
  
//     fbMessaging.onMessage((payload) => {
//       console.log("onMessage: ", payload);
//     })
//   } else {
//     console.log("FCM is not supported.")
//   }
// } catch {
//   console.log("error");
// }

// stay logged in even after brower closing
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// export utils/refs
export {
  fbAuth,
  googleProvider,
  fbDB,
  fbStorage,
  // fbMessaging,
  fbFunctions,
}


