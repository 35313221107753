
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonIcon,
  IonButtons,
  IonItem,
  IonButton,
  modalController,
  loadingController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import {
  personOutline,
  closeOutline,
  mailOutline,
  eyeOffOutline,
  eyeOutline,
  lockClosedOutline,
  giftOutline,
  logoGoogle,
  logoApple,
  logoFacebook,
} from "ionicons/icons";
import router from "@/router";

import { signupClassic } from "@/auth";
import { openToast } from "@/general/toasts";

import * as V from "vee-validate/dist/vee-validate";
import { defineRule } from "vee-validate/dist/vee-validate";
import { required, email, min, max, alpha } from "@vee-validate/rules";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "RegistrationModal",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonIcon,
    IonButtons,
    IonItem,
    IonButton,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });

    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    let loadingSpinner: HTMLIonLoadingElement | undefined;
    const loadingSpinnerOpen = async () => {
      loadingSpinner = await loadingController.create({
        cssClass: "my-custom-class",
        translucent: true,
        message: t("titlePage.registrationInProgress"),
      });

      await loadingSpinner.present();
    };

    defineRule("required", required);
    defineRule("email", email);
    defineRule("min", min);
    defineRule("max", max);
    defineRule("alpha", alpha);

    const aggressiveValidation = ref<{ [key: string]: boolean }>({
      name: false,
      email: false,
      password: false,
      year: false,
    });
    const enableAggressiveValidation = (fieldId: string) => {
      aggressiveValidation.value[fieldId] = true;
    };

    const showPassword = ref(false);
    const sex = ref<string>("");
    const sexNotSelected = ref<boolean>(false);
    const changeSex = (sexSelected: string) => {
      sexNotSelected.value = false;
      sex.value = sexSelected;
    };

    const continueRegistration = async (values: any, actions: any) => {
      if (sex.value == "") {
        sexNotSelected.value = true;
        return;
      }
      loadingSpinnerOpen();

      values["sex"] = sex.value;
      values["name"] =
        values["name"][0].toUpperCase() + values["name"].slice(1).toLowerCase();

      const { result, code } = await signupClassic(values);

      if (result == "success") {
        closeModal();
        loadingSpinner?.dismiss();
        router.replace({ name: "registrationCriteria" });
      } else if (result == "authFail") {
        if (code == "auth/email-already-in-use") {
          actions.setFieldError("email", t("authValidation.emailAlreadyInUse"));
        } else if (code == "auth/weak-password") {
          actions.setFieldError("password", t("authValidation.weakPassword"));
        } else if (code == "auth/invalid-email") {
          actions.setFieldError("email", t("authValidation.invalidEmail"));
        } else if (code == "auth/operation-not-allowed") {
          console.log("Email auth not allowed on server side.");
        } else if (code == "auth/network-request-failed") {
          openToast("networkConnectionFailed", false);
        } else {
          openToast("registrationFailedUnknown", false);
          console.log(code);
        }
        loadingSpinner?.dismiss();
      } else if (result == "dbFail") {
        closeModal();
        loadingSpinner?.dismiss();
        router.replace("/registration/base");
      }
      loadingSpinner?.dismiss();
    };

    return {
      aggressiveValidation,
      enableAggressiveValidation,
      sexNotSelected,
      continueRegistration,
      required,
      email,
      min,
      max,
      sex,
      showPassword,
      changeSex,
      closeModal,
      // ICONS
      personOutline,
      closeOutline,
      mailOutline,
      eyeOffOutline,
      eyeOutline,
      lockClosedOutline,
      giftOutline,
      logoGoogle,
      logoApple,
      logoFacebook,
    };
  },
});
