<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="ios">
        <ion-buttons slot="end">
          <ion-button @click="closeModal()" color="primary">
            {{$t("buttons.done")}}
          </ion-button>
        </ion-buttons>
        <ion-title>
         <img src="/assets/logo.png" style="height:30px;"/>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card
        style="margin: 0px; border-radius: 0px; box-shadow: none"
      >
        <ion-card-header>
          <ion-card-subtitle>{{$t("criteria.religion.religion")}}</ion-card-subtitle>
          <ion-card-title
            >{{$t("criteria.religion.question")}}</ion-card-title
          >
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-item v-for="(rel, index) in religionList" :key="index">
              <ion-checkbox
                id="lang.code"
                color="primary"
                slot="start"
                :value="rel.code"
                @update:modelValue="rel.isChecked = $event"
                :modelValue="rel.isChecked"
                @ionChange="checkboxChanged($event)"
              >
                ></ion-checkbox
              >
              <ion-label>{{ $t("criteria.religion.options."+ rel.code) }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonTitle,
  IonCheckbox,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline } from "ionicons/icons";
import { myCriteria } from "@/components/criteria";
import jsonData from "@/assets/criteriaData.json";

export default defineComponent({
  name: "Religion",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonList,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonTitle,
    IonCheckbox,
  },
  props: {},
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const religionList = ref(jsonData.religions);

    religionList.value.forEach((item) => {
     if (myCriteria.value.religions) {
        if ( myCriteria.value.religions?.[item.code] ) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
    });

    const checkboxChanged = (event: any) => {
      // console.log(event);
      const relCode: string = event.detail.value;
     if (!myCriteria.value.religions) {
        myCriteria.value.religions = {};
      }

      if (event.detail.checked == false) {
        delete myCriteria.value.religions[relCode];
      } else if (event.detail.checked == true) {
        myCriteria.value.religions[relCode] = true;
      }
    };

    return {
      closeModal,
      religionList,
      checkboxChanged,
      checkmarkOutline,
    };
  },
});
</script>

<style scoped>
ion-item {
  --background: inherit;
}
</style>