import { ref } from "vue";
import { fbDB, fbFunctions } from "@/firebase";
import { user } from "@/auth";
import { loadProfileByUid } from "@/components/profile/show";

const findSuggestionsCF = fbFunctions.httpsCallable('findPartnerSuggestionsOnCall');
const sendLikeCF = fbFunctions.httpsCallable('sendLike');
const sendDislikeCF = fbFunctions.httpsCallable('sendDislike');



/** References */
export const suggestionsList = ref<Array<string>>([]);
export const currentSuggestion = ref<object | null>({});
export const suggestionPhotos = ref<string[]>([]);

export const findingOnLoader = ref<boolean>(true);
export const noMoreSuggestions = ref<boolean>(false);
export const loadingSuggestionProfile = ref<boolean>(false);
export const errorOccured = ref<boolean>(false);


export async function findNewSuggestions() {
  findingOnLoader.value = true;
  suggestionsList.value = [];
  currentSuggestion.value = null;

  // check last search
  const currentTimestamp = new Date().getTime();
  let lastFindTimestamp;
  try {
    const lastFindSnap = await fbDB
      .ref(`/users/${user.value.uid}/suggestions/lastFind`)
      .once("value");
    lastFindTimestamp = lastFindSnap.val();
  } catch (error) {
    errorOccured.value = true;
    console.log(error);
    return;
  }
  /**
   * If last finding was in 3 hours before this one, 
   * do not run finding.
   */
  if (lastFindTimestamp != null) {
    if (currentTimestamp - lastFindTimestamp < 10800000) {
      findingOnLoader.value = false;
      return;
    }
  }

  try {
    const newSuggestions = await findSuggestionsCF({force: false});
    suggestionsList.value = Object.keys(newSuggestions.data);
  } catch (err) {
    errorOccured.value = true;
    // console.log(err);
  } finally {
    findingOnLoader.value = false;
  }
}

export async function getNextSuggestedProfile() {
  if (suggestionsList.value.length == 0) {
    await findNewSuggestions();
    if (suggestionsList.value.length == 0) {
      noMoreSuggestions.value = true;
      return;
    } else {
      noMoreSuggestions.value = false;
    }
  } else {
    noMoreSuggestions.value = false;
  }
  const uid: string | undefined = suggestionsList.value.pop();

  if (uid) {
    loadingSuggestionProfile.value = true;
    try {
      const result = await loadProfileByUid(uid);
      if (result != null) {
        currentSuggestion.value = result.profile;
        suggestionPhotos.value = result.allPhotos;
      } else {
        currentSuggestion.value = null;
      }
    } catch (err) {
      errorOccured.value = true;
      currentSuggestion.value = null;
    } finally {
      loadingSuggestionProfile.value = false;
    }
  } else {
    errorOccured.value = true;
    console.log("nextProfile() uid == undefined.")
  }
}

/**
 * call after criteria change
 */
export async function getNewSuggestedProfile() {
  findingOnLoader.value = true;
  suggestionsList.value = [];
  currentSuggestion.value = null;

  try {
    const newSuggestions = await findSuggestionsCF({force: true});
    suggestionsList.value = Object.keys(newSuggestions.data);
    await getNextSuggestedProfile();
  } catch (err) {
    console.log(err);
    console.log(err.code);
    errorOccured.value = true;
  } finally {
    findingOnLoader.value = false;
  }

}



export async function loadMySuggestions() {
  findingOnLoader.value = true;
  noMoreSuggestions.value = false;
  loadingSuggestionProfile.value = false;
  errorOccured.value = false;
  suggestionsList.value = [];

  try {
    const suggestionsSnap = await fbDB.ref(`/users/${user.value.uid}/suggestions/list`)
      .once("value");

    if (suggestionsSnap.val() == null) { // no suggestions - no /suggestions field
      await findNewSuggestions();
      if (suggestionsList.value.length == 0) {
        noMoreSuggestions.value = true;
        return;
      }
    } else {
      suggestionsList.value = Object.keys(suggestionsSnap.val());
    }

    await getNextSuggestedProfile();

  } catch (err) {
    errorOccured.value = true;
    console.log(err);
  } finally {
    findingOnLoader.value = false;
  }
}


export async function sendLike(partnerUid: string) {
  loadingSuggestionProfile.value = true;
  sendLikeCF({ uid: partnerUid }).then((data: any) => {
    getNextSuggestedProfile();
  }).catch((err) => {
    console.log(err);
  })
}

export async function sendDislike(partnerUid: string, fromLikes: boolean) {
  loadingSuggestionProfile.value = true;
  sendDislikeCF({ uid: partnerUid, fromLikes: fromLikes }).then((data: any) => {
    getNextSuggestedProfile();
  }).catch((err) => {
    console.log(err);
  })
}

