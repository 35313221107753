
import {
  IonPage,
  IonContent,
  IonItem,
  IonButton,
  IonIcon,
  IonSpinner,
  modalController,
  isPlatform,
  toastController,
} from "@ionic/vue";
import { ref } from "vue";
import {
  mailOutline,
  eyeOffOutline,
  eyeOutline,
  lockClosedOutline,
  logoFacebook,
  logoApple,
  logoGoogle,
} from "ionicons/icons";

import RegistrationModal from "@/components/registration/RegistrationModal.vue";
import PasswordResetModal from "@/components/account/PasswordResetModal.vue";

import router from "@/router";
import { loginClassic } from "@/auth";

import * as V from "vee-validate/dist/vee-validate";
import { defineRule, configure } from "vee-validate/dist/vee-validate";
import { required, email, min, max } from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";

import { openToast } from "@/general/toasts";
import { presentLanguageSelector } from "@/settings/settings";
import { useI18n } from "vue-i18n";

import sk from "@/locales/sk.json";
import en from "@/locales/gb.json";

export default {
  name: "LoginPage",
  components: {
    IonPage,
    IonContent,
    IonItem,
    IonButton,
    IonIcon,
    IonSpinner,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    const showPassword = ref<boolean>(false);

    const openRegistrationModal = async () => {
      const modal = await modalController.create({
        component: RegistrationModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        animated: true,
        swipeToClose: true,
      });
      return modal.present();
    };

    const loggingOngoing = ref<boolean>(false);

    const loginLocal = async (values: any, actions: any) => {
      loggingOngoing.value = true;
      const { result, code } = await loginClassic(values);
      if (result == "success") {
        const toast = await toastController.getTop();
        toast?.dismiss();

        if (code == "ok") {
          if (router.currentRoute.value.query.redirect) {
            router.replace(router.currentRoute.value.query.redirect + "");
          } else {
            router.replace("/my-account");
          }
        } else if (code == "registrationCriteria") {
          router.replace({ name: "registrationCriteria" });
        } else if (code == "registrationProfile") {
          router.replace({ name: "registrationProfile" });
        } else if (code == "registrationBase") {
          router.replace({ name: "registrationBase" });
        } else {
          if (router.currentRoute.value.query.redirect) {
            router.replace(router.currentRoute.value.query.redirect + "");
          } else {
            router.replace("/my-account");
          }
        }
      } else {
        loggingOngoing.value = false;
        if (code == "auth/wrong-password") {
          actions.setFieldError("password", t("authValidation.wrongPassword"));
        } else if (code == "auth/user-not-found") {
          actions.setFieldError("email", t("authValidation.userNotFound"));
        } else if (code == "auth/invalid-email") {
          actions.setFieldError("email", t("authValidation.invalidEmail"));
        } else if (code == "auth/user-disabled") {
          actions.setFieldError("email", t("authValidation.userDisabled"));
        } else if (code == "auth/network-request-failed") {
          openToast("networkConnectionFailed", false);
        } else {
          openToast("loginFailedUnknown", false);
          console.log(code);
        }
      }
    };

    /** ==================== VALIDATION ==================== */
    defineRule("required", required);
    defineRule("email", email);
    defineRule("min", min);
    defineRule("max", max);

    configure({
      // Generates an English message locale generator
      generateMessage: localize({
        sk: {
          messages: sk.validation,
        },
        en: {
          messages: en.validation,
        },
      }),
    });

    if (localStorage.language) {
      if (localStorage.language == "gb") {
        setLocale("en");
      } else {
        setLocale("sk");
      }
    } else {
      setLocale("sk");
    }

    const aggressiveValidation = ref<{ [key: string]: boolean }>({
      email: false,
      password: false,
    });
    const enableAggressiveValidation = (fieldId: string) => {
      aggressiveValidation.value[fieldId] = true;
    };

    const resetPassword = async () => {
      const modal = await modalController.create({
        component: PasswordResetModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        animated: true,
        swipeToClose: true,
      });
      return modal.present();
    };

    return {
      // xxx,
      loggingOngoing,
      resetPassword,
      locale,
      presentLanguageSelector,
      openRegistrationModal,
      aggressiveValidation,
      enableAggressiveValidation,
      showPassword,

      email,
      required,
      min,
      max,
      loginLocal,

      // template components
      mailOutline,
      eyeOffOutline,
      eyeOutline,
      lockClosedOutline,
      logoFacebook,
      logoApple,
      logoGoogle,
    };
  },
};
