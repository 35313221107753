
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonBadge,
  toastController,
} from "@ionic/vue";
import {
  personCircleOutline,
  heartOutline,
  mailOutline,
  peopleOutline,
  flashOutline,
} from "ionicons/icons";
import { computed } from "vue";

import { listOfChats, loadChats } from "@/components/messages";
import { listOfLikes, loadLikes } from "@/components/likes";
import { myContacts, ContactsListInterface, loadMyContacts } from "@/components/contacts";
import { registrationProcess, user } from "@/auth";
import { fbDB } from "@/firebase";
import router from "@/router";
import { useI18n } from "vue-i18n";

export default {
  name: "Tabs",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonBadge,
    // IonRouterOutlet,
  },
  setup() {
    // TODO
    // const allowedUsers = ["3IzTgLkdV7ZwuKU4bPfEgrsTHGp2", "ByD2w0e6N3YTAHeBuL9Qw7Xa7oO2", "k4rbNyfy5qWB2fAz24iAQfyxaiH3"];
    // if ( !allowedUsers.includes(user.value.uid)) {
    //   router.replace("/laska");
    // }

    loadChats();
    loadLikes();
    loadMyContacts();

    const countOfLikes = computed(() => {
     if (listOfLikes.value) {
        const count = Object.keys(listOfLikes.value).length;
        if (count > 9) {
          return "9+";
          } else {
            return count;
        }
      } else {
        return 0;
      }
    });

    const unreadMessagesCount = computed(() => {
      const unreadChats = listOfChats.value.filter(
        (chat: any) => chat.lastMessage?.seen == false
      );
      return unreadChats.length;
    });

    const newContactsCount = computed(() => {
      let count = 0;
      let initialLetter: keyof ContactsListInterface;
      for (initialLetter in myContacts.value) {
        for (const uid in myContacts.value[initialLetter]) {
          if (myContacts.value[initialLetter][uid]["new"]) {
            count = count + 1;
          }
        }
      }
      return count;
    });

    const { t } = useI18n({ useScope: "global" });

    const openToast = async (type: string) => {
      const toast = await toastController.create({
        message:
          type == "profile"
            ? t("registration.messageMisingProfile")
            : t("registration.messageMisingCriteria"),
        position: "bottom",
        color: "danger",
        buttons: [
          {
            text: t("buttons.fillin"),
            role: "cancel",
            handler: () => {
              router.push("/registration/" + type);
              toast.dismiss();
            },
          },
        ],
      });
      return toast.present();
    };

    const checkRegistrationProcess = async () => {
      if (!user.value.displayName) {
        router.replace("/registration/base");
        return;
      }

      const regStepSnap = await fbDB
        .ref(`/users/${user.value.uid}/userInfo/registration`)
        .once("value");
      const regStep = regStepSnap.val();
      if (regStep != null) {
        const topToast = await toastController.getTop();
        if (topToast != undefined) {
          return;
        }

        registrationProcess.value["base"] = regStep.base || false;
        registrationProcess.value["criteria"] = regStep.criteria || false;
        registrationProcess.value["profile"] = regStep.profile || false;
        if (registrationProcess.value["base"] == false) {
          router.replace("/registration/base");
        } else if (
          registrationProcess.value["criteria"] &&
          registrationProcess.value["profile"]
        ) {
          return;
        } else if (registrationProcess.value["criteria"] == false) {
          // openToast("criteria");
          router.replace("/registration/criteria");
        } else if (registrationProcess.value["profile"] == false) {
          // openToast("profile");
          router.replace("/registration/profile");
        }
      } else {
        router.replace("/registration/base");
      }
    };

    checkRegistrationProcess();

    return {
      countOfLikes,
      newContactsCount,
      unreadMessagesCount,
      personCircleOutline,
      heartOutline,
      mailOutline,
      peopleOutline,
      flashOutline,
    };
  },
};
