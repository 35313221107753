
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonTitle,
  IonCheckbox,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline } from "ionicons/icons";
import { myCriteria } from "@/components/criteria";
import jsonData from "@/assets/criteriaData.json";

import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Location",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonTitle,
    IonCheckbox,
  },
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };
    
    const locationsList = ref(jsonData.locations);
    
    locationsList.value.forEach((item) => {
       if (myCriteria.value.locations) {
        if ( myCriteria.value.locations?.[item.code] ) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
    });

    /** Add or remove language to/from criteria directory. */
    const checkboxChanged = (event: any) => {
      const locCode: string = event.detail.value;
      if (!myCriteria.value.locations) {
        myCriteria.value.locations = {}
      }

      if (event.detail.checked == false) {
        delete myCriteria.value.locations[locCode];
      } else if (event.detail.checked == true) {
        myCriteria.value.locations[locCode] = true;
      }
    };

    return {
      checkboxChanged,
      locationsList,
      closeModal,
      checkmarkOutline,
    };
  },
});
