<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div
        style="max-width: 400px; margin: auto; height: 100%; padding-top: 4rem"
      >
        <div style="text-align: center">
          <img
            src="/assets/logo_laska.png"
            alt="Láska logo"
            style="
              max-width: 300px;
              margin: auto;
              height: 180px;
              margin-bottom: 2rem;
            "
          />
        </div>
        <v-form @submit="loginLocal">
          <ion-item lines="none" class="fieldItem">
            <ion-icon
              :icon="mailOutline"
              slot="start"
              style="color: #999"
            ></ion-icon>
            <v-field
              id="emailField"
              name="email"
              v-slot="{ field }"
              value=""
              rules="required|email"
              :validateOnInput="aggressiveValidation.email"
              :validateOnBlur="false"
            >
              <input
                @change="enableAggressiveValidation('email')"
                :value="field.value"
                name="email"
                class="inputField"
                v-bind="field"
                placeholder="E-mail"
                type="email"
              />
            </v-field>
          </ion-item>
          <v-error-message name="email" class="error"> </v-error-message>

          <ion-item lines="none" class="fieldItem">
            <ion-icon
              :icon="lockClosedOutline"
              slot="start"
              style="color: #999"
            ></ion-icon>
            <v-field
              name="password"
              type="password"
              v-slot="{ field }"
              :validateOnInput="aggressiveValidation.password"
              :validateOnBlur="false"
              rules="required|min:8"
            >
              <input
                @change="enableAggressiveValidation('password')"
                name="password"
                v-bind="field"
                class="inputField"
                :placeholder="$t('titlePage.password')"
                :type="showPassword ? 'text' : 'password'"
              />
            </v-field>
            <ion-icon
              @click="showPassword = !showPassword"
              :icon="showPassword ? eyeOutline : eyeOffOutline"
              slot="end"
              style="cursor: pointer; color: #999"
            ></ion-icon>
          </ion-item>
          <v-error-message name="password" class="error" />

          <ion-button
            mode="ios"
            type="submit"
            expand="block"
            shape="round"
            color="primary"
            style="margin-top: 15px; height: 3rem"
            :disabled="loggingOngoing"
          >
            <ion-spinner v-if="loggingOngoing"></ion-spinner>
            <span v-else>{{ $t("titlePage.login") }}</span>
          </ion-button>
        </v-form>
        <p
          @click="resetPassword()"
          style="
            cursor: pointer;
            text-align: center;
            margin-top: 0.5rem;
            color: #444;
            font-size: smaller;
          "
        >
          {{ $t("titlePage.forgotPassword") }}
        </p>

        <p style="text-align: center; font-size: smaller; color: #444">
          {{ $t("titlePage.notRegistrated") }}
          <span
            style="font-weight: bold; color: black; cursor: pointer"
            @click="openRegistrationModal()"
            >{{ $t("titlePage.goRegister") }}</span
          >
        </p>

        <div style="text-align: center; padding-bottom: 0.5rem">
          <p style="margin: 1rem">
            <i18n-t
              keypath="titlePage.recaptchaProtect"
              tag="small"
              style="color: #bbb"
            >
              <template v-slot:privacy>
                <a
                  style="color: inherit"
                  target="_blank"
                  href="https://policies.google.com/privacy"
                  >{{ $t("titlePage.privacyPolicy") }}</a
                >
              </template>
              <template v-slot:terms>
                <a
                  style="color: inherit"
                  target="_blank"
                  href="https://policies.google.com/terms"
                  >{{ $t("titlePage.termsOfService") }}</a
                >
              </template>
            </i18n-t>
          </p>
          <small style="color: #bbb"
            >© Laska 2022 |
            <span @click="presentLanguageSelector()">
              <span style="cursor: pointer">{{
                locale == "gb" ? "EN" : "SK"
              }}</span>
            </span>
            |
            <a
              href="https://www.laska.club"
              style="color: #bbb; text-decoration: none"
              >laska.club</a
            ></small
          >
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonContent,
  IonItem,
  IonButton,
  IonIcon,
  IonSpinner,
  modalController,
  isPlatform,
  toastController,
} from "@ionic/vue";
import { ref } from "vue";
import {
  mailOutline,
  eyeOffOutline,
  eyeOutline,
  lockClosedOutline,
  logoFacebook,
  logoApple,
  logoGoogle,
} from "ionicons/icons";

import RegistrationModal from "@/components/registration/RegistrationModal.vue";
import PasswordResetModal from "@/components/account/PasswordResetModal.vue";

import router from "@/router";
import { loginClassic } from "@/auth";

import * as V from "vee-validate/dist/vee-validate";
import { defineRule, configure } from "vee-validate/dist/vee-validate";
import { required, email, min, max } from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";

import { openToast } from "@/general/toasts";
import { presentLanguageSelector } from "@/settings/settings";
import { useI18n } from "vue-i18n";

import sk from "@/locales/sk.json";
import en from "@/locales/gb.json";

export default {
  name: "LoginPage",
  components: {
    IonPage,
    IonContent,
    IonItem,
    IonButton,
    IonIcon,
    IonSpinner,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    const showPassword = ref<boolean>(false);

    const openRegistrationModal = async () => {
      const modal = await modalController.create({
        component: RegistrationModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        animated: true,
        swipeToClose: true,
      });
      return modal.present();
    };

    const loggingOngoing = ref<boolean>(false);

    const loginLocal = async (values: any, actions: any) => {
      loggingOngoing.value = true;
      const { result, code } = await loginClassic(values);
      if (result == "success") {
        const toast = await toastController.getTop();
        toast?.dismiss();

        if (code == "ok") {
          if (router.currentRoute.value.query.redirect) {
            router.replace(router.currentRoute.value.query.redirect + "");
          } else {
            router.replace("/my-account");
          }
        } else if (code == "registrationCriteria") {
          router.replace({ name: "registrationCriteria" });
        } else if (code == "registrationProfile") {
          router.replace({ name: "registrationProfile" });
        } else if (code == "registrationBase") {
          router.replace({ name: "registrationBase" });
        } else {
          if (router.currentRoute.value.query.redirect) {
            router.replace(router.currentRoute.value.query.redirect + "");
          } else {
            router.replace("/my-account");
          }
        }
      } else {
        loggingOngoing.value = false;
        if (code == "auth/wrong-password") {
          actions.setFieldError("password", t("authValidation.wrongPassword"));
        } else if (code == "auth/user-not-found") {
          actions.setFieldError("email", t("authValidation.userNotFound"));
        } else if (code == "auth/invalid-email") {
          actions.setFieldError("email", t("authValidation.invalidEmail"));
        } else if (code == "auth/user-disabled") {
          actions.setFieldError("email", t("authValidation.userDisabled"));
        } else if (code == "auth/network-request-failed") {
          openToast("networkConnectionFailed", false);
        } else {
          openToast("loginFailedUnknown", false);
          console.log(code);
        }
      }
    };

    /** ==================== VALIDATION ==================== */
    defineRule("required", required);
    defineRule("email", email);
    defineRule("min", min);
    defineRule("max", max);

    configure({
      // Generates an English message locale generator
      generateMessage: localize({
        sk: {
          messages: sk.validation,
        },
        en: {
          messages: en.validation,
        },
      }),
    });

    if (localStorage.language) {
      if (localStorage.language == "gb") {
        setLocale("en");
      } else {
        setLocale("sk");
      }
    } else {
      setLocale("sk");
    }

    const aggressiveValidation = ref<{ [key: string]: boolean }>({
      email: false,
      password: false,
    });
    const enableAggressiveValidation = (fieldId: string) => {
      aggressiveValidation.value[fieldId] = true;
    };

    const resetPassword = async () => {
      const modal = await modalController.create({
        component: PasswordResetModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        animated: true,
        swipeToClose: true,
      });
      return modal.present();
    };

    return {
      // xxx,
      loggingOngoing,
      resetPassword,
      locale,
      presentLanguageSelector,
      openRegistrationModal,
      aggressiveValidation,
      enableAggressiveValidation,
      showPassword,

      email,
      required,
      min,
      max,
      loginLocal,

      // template components
      mailOutline,
      eyeOffOutline,
      eyeOutline,
      lockClosedOutline,
      logoFacebook,
      logoApple,
      logoGoogle,
    };
  },
};
</script>

<style scoped>
.myCustomClass .modal-wrapper {
  --border-radius: 25px;
  --background: #eee;
}

h6 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #999;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

h6 span {
  font-size: smaller;
  color: #999;
  background: #fff;
  padding: 0 10px;
}

.registrationModalClass {
  border-radius: 25px;
}

.error {
  display: block;
  margin: 5px 1.5rem;
  font-weight: light;
  font-size: smaller;
  color: rgb(228, 2, 2);
}

ion-item.fieldItem {
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-top: 5px;
  height: 3rem;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

ion-item.fieldItem:hover {
  border-color: #808080;
}

ion-item {
  --ion-item-background: white;
  --ion-text-color: black;
}

.inputField {
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
}

ion-content {
  --background: white !important;
}
</style>