<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="ios">
        <ion-buttons slot="end">
          <ion-button @click="closeModal()" color="primary">
            {{ $t("buttons.done") }}
          </ion-button>
        </ion-buttons>
        <ion-title>
          <img src="/assets/logo.png" style="height: 30px" />
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card
        class="flexcroll"
        style="
          overflow-y: scroll;
          margin: 0px;
          border-radius: 0px;
          box-shadow: none;
          height: 100%;
        "
      >
        <ion-card-header id="header">
          <ion-card-subtitle>{{ $t("criteria.work.work") }}</ion-card-subtitle>
          <ion-card-title>{{ $t("criteria.work.question") }}</ion-card-title>
          <div>
            <ion-searchbar
              v-model="searchQuery"
              :placeholder="$t('buttons.search')"
              style="padding-left: 0px; padding-right: 0px"
            ></ion-searchbar>
            <p v-if="myCriteria.works">
              <ion-chip
                @click="scrollToJob(code)"
                v-for="(value, code) in myCriteria.works"
                :key="code"
                >{{ $t(`profile.work.options.` + code) }}</ion-chip
              >
            </p>
          </div>
        </ion-card-header>
        <ion-card-content>
          <ion-item
            v-for="(job, index) in filteredJobsList"
            :key="index.code"
            :id="job.code"
          >
            <ion-checkbox
              color="primary"
              slot="start"
              :value="job.code"
              @update:modelValue="job.isChecked = $event"
              :modelValue="job.isChecked"
              @ionChange="checkboxChanged($event)"
            ></ion-checkbox>
            <ion-label>{{ $t(`profile.work.options.` + job.code) }}</ion-label>
          </ion-item>
        </ion-card-content>
      </ion-card>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="scrollToTop()" size="small" color="light">
          <ion-icon :icon="arrowUpOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonChip,
  IonIcon,
  IonLabel,
  IonFab,
  IonFabButton,
  IonTitle,
  IonCheckbox,
  IonSearchbar,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline, arrowUpOutline } from "ionicons/icons";
import jsonData from "@/assets/criteriaData.json";
import { myCriteria } from "@/components/criteria";

export default defineComponent({
  name: "Work",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonChip,
    IonCard,
    IonIcon,
    IonCardHeader,
    IonFab,
    IonFabButton,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonTitle,
    IonCheckbox,
    IonSearchbar,
  },
  props: {},
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const listOfJobs = ref(jsonData.jobs);
    listOfJobs.value.forEach((item) => {
      if (myCriteria.value.works) {
        if (myCriteria.value.works?.[item.code]) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
    });

    const searchQuery = ref("");
    const lang = localStorage.language == "gb" ? "en" : "sk";
    const filteredJobsList = computed(() => {
      const filtered = listOfJobs.value.filter((item) => {
        return (
          item[lang].toLowerCase().indexOf(searchQuery.value.toLowerCase()) !==
          -1
        );
      });
      return filtered.sort((a: any, b: any) => {
        if (a[lang] < b[lang]) {
          return -1;
        }
        if (a[lang] > b[lang]) {
          return 1;
        }
        return 0;
      });
    });

    const checkboxChanged = (event: any) => {
      const workCode: string = event.detail.value;
      if (!myCriteria.value.works) {
        myCriteria.value.works = {};
      }

      if (event.detail.checked == false) {
        delete myCriteria.value.works[workCode];
      } else if (event.detail.checked == true) {
        myCriteria.value.works[workCode] = true;
      }
    };

    const scrollToJob = (jobCode: string) => {
      document.getElementById(jobCode)?.scrollIntoView({behavior: "smooth"});
    };

    const scrollToTop = () => {
      document.querySelector("ion-card-header#header")?.scrollIntoView({behavior: "smooth"});
    };

    return {
      scrollToTop,
      arrowUpOutline,
      scrollToJob,
      myCriteria,
      filteredJobsList,
      searchQuery,
      closeModal,
      checkmarkOutline,
      checkboxChanged,
    };
  },
});
</script>

<style scoped>
ion-item {
  --background: inherit;
}
/* Let's get this party started */
/* .flexcroll::-webkit-scrollbar {
    width: 12px;
} */

/* Track */
/* .flexcroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
} */

/* Handle */
/* .flexcroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255,0,0,0.8); 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
} */
</style>