import { ref, reactive, onMounted, } from "vue";
import { modalController, isPlatform } from "@ionic/vue";


import Education from '@/components/criteria/modals/Education.vue';
import Smoking from '@/components/criteria/modals/Smoking.vue';
import Work from '@/components/criteria/modals/Work.vue';
import Religion from '@/components/criteria/modals/Religion.vue';
import Languages from '@/components/criteria/modals/Languages.vue';
import Locations from '@/components/criteria/modals/Locations.vue';

import { fbDB } from "@/firebase";
import { registrationProcess, user } from "@/auth";
import { getNewSuggestedProfile } from '@/components/suggestions';
import { myContacts } from "../contacts";



interface CriteriaInt {
  sex?: string;
  ageRange?: { lower: number; upper: number };
  education?: string;
  smoking?: string;
  works?: { [key: string]: true };
  religions?: { [key: string]: true };
  languages?: { [key: string]: true };
  locations?: { [key: string]: true };
}
export const myCriteria = ref<CriteriaInt>({});
export const oldCriteria = ref<CriteriaInt>({});
export const loadingMyCriteria = ref<boolean>(true);
export const validationOn = ref<boolean>(false);
export const errorOccured = ref<boolean>(false);

export async function loadMyCriteria() {
  loadingMyCriteria.value = true;
  myCriteria.value = {};
  let criteria;
  try {
    const criteriaSnap = await fbDB.ref(`users/${user.value.uid}/criteria`).once('value');
    criteria = criteriaSnap.val();
  } catch (error) {
    errorOccured.value = true;
    loadingMyCriteria.value = false;
    console.log(error);
    return;
  }

  if (criteria) {
    myCriteria.value = criteria;
    oldCriteria.value = JSON.parse(JSON.stringify(criteria));
  }
  loadingMyCriteria.value = false;  
}

export function clearCriteria() {
  myCriteria.value = {};
  // delete myCriteria.sex;
  // delete myCriteria.ageRange;
  // delete myCriteria.education;
  // delete myCriteria.smoking;
  // myCriteria.work = new Set();
  // myCriteria.religion = new Set();
  // myCriteria.languages = new Set();
  // myCriteria.locations = new Set();
}

export function validateCriteriaAreNok() {
  if (
    myCriteria.value.sex &&
    myCriteria.value.ageRange &&
    myCriteria.value.locations
  ) {
    if (Object.keys(myCriteria.value.locations).length != 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export function checkCriteriaChange() {
  if (!oldCriteria.value) {
    return true;
  }

  if (oldCriteria.value.sex != myCriteria.value.sex){
    return true;
  } else if (oldCriteria.value.smoking != myCriteria.value.smoking ) {
    return true;
  } else if (oldCriteria.value.education != myCriteria.value.education ) {
    return true;
  } 
  
  /** LOCATIONS check */
  const oldLocationsArray = Object.keys(oldCriteria.value.locations || {});
  const newLocationsArray = Object.keys(myCriteria.value.locations || {});
  if (oldLocationsArray.length == newLocationsArray.length) {
    if (!(oldLocationsArray.every((val) => newLocationsArray.includes(val)))) {
      return true;
    } 
  } else {
    return true;
  }

  /** WORKS check */
  const oldWorksArray = Object.keys(oldCriteria.value.works || {});
  const newWorksArray = Object.keys(myCriteria.value.works || {});
  if (oldWorksArray.length == newWorksArray.length) {
    if (!(oldWorksArray.every((val) => newWorksArray.includes(val)))) {
      return true;
    } 
  } else {
    return true;
  }

  /** RELIGIONS check */
  const oldReligionsArray = Object.keys(oldCriteria.value.religions || {});
  const newReligionsArray = Object.keys(myCriteria.value.religions || {});
  if (oldReligionsArray.length == newReligionsArray.length) {
    if (!(oldReligionsArray.every((val) => newReligionsArray.includes(val)))) {
      return true;
    } 
  } else {
    return true;
  }
  
  /** LANGUAGES check */
  const oldLanguagesArray = Object.keys(oldCriteria.value.languages || {});
  const newLanguagesArray = Object.keys(myCriteria.value.languages || {});
  if (oldLanguagesArray.length == newLanguagesArray.length) {
    if (!(oldLanguagesArray.every((val) => newLanguagesArray.includes(val)))) {
      return true;
    } 
  } else {
    return true;
  }

  return false;
}

export async function saveMyCriteria() {
  if (checkCriteriaChange() == false) {
    try {
      await fbDB.ref(`users/${user.value.uid}/userInfo/registration/criteria`).set(true);
    } catch(err) {
      console.log(err);
    } 
    return { result: 'success' };
  }
  
  const updates: { [key: string]: any } = {};
  updates[`users/${user.value.uid}/criteria/`] = myCriteria.value;
  updates[`users/${user.value.uid}/finder/searchedSex`] = myCriteria.value.sex;
  updates[`users/${user.value.uid}/userInfo/registration/criteria`] = true;
  try {
    await fbDB.ref().update(updates);
    getNewSuggestedProfile();
    registrationProcess.value["criteria"] = true;
    return { result: 'success' };
  } catch (error) {
    return { result: 'fail', error: error };
  }
}

export async function openEducationModal() {
  const modal = await modalController.create({
    component: Education,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}

export async function openWorkModal() {
  const modal = await modalController.create({
    component: Work,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}

export async function openSmokingModal() {
  const modal = await modalController.create({
    component: Smoking,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}

export async function openReligionModal() {
  const modal = await modalController.create({
    component: Religion,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}

export async function openLanguagesModal() {
  const modal = await modalController.create({
    component: Languages,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}

export async function openLocationsModal() {
  const modal = await modalController.create({
    component: Locations,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}