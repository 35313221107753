import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window);

import { fbAuth } from "@/firebase";
import {user} from "@/auth";

import "@/assets/global.css"

import { createI18n } from 'vue-i18n'
import sk from "@/locales/sk.json";
import gb from "@/locales/gb.json";
import ru from "@/locales/ru.json";


import CountryFlag from 'vue-country-flag-next';

export const i18n = createI18n({
  locale: localStorage.language || 'sk', // set locale
  fallbackLocale: 'sk', // set fallback locale
  messages: {sk, gb, ru}, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});

// wait for observer to finish (get current user before NavGuard of router)
let app: any = undefined;
fbAuth.onAuthStateChanged((u) => {
  user.value = u;
  if (!app) {
    app = createApp(App)
      .use(IonicVue)
      .use(router)
      .use(i18n)
      .use(CountryFlag)
    router.isReady().then(() => {
      app.mount('#app');
    });
  }
})