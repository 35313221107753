import { ref } from "vue";
import { fbDB } from "@/firebase";

import profileData from "@/assets/criteriaData.json";

// export const profileInfo = ref<any>({});
// export const loadingProfile = ref<boolean>(true);
// export const allPhotos: string[] = [];


async function parseFullProfileFromDB(uid: string, snap: any) {
  const profile: {[key: string]: any}|null = {};
  profile["uid"] = uid;
  profile["name"] = snap.baseInfo.name;
  profile["bio"] = snap.baseInfo.bio;
  if (snap.baseInfo?.profilePhoto != "") {
    profile["profilePhoto"] = snap.baseInfo?.profilePhoto;
  }
  if (snap.baseInfo.titlePhoto != "") {
    profile["titlePhoto"] = snap.baseInfo.titlePhoto;
  }
  for (const item of profileData.education) {
    if (item.code == snap.baseInfo.mainInfo.education) {
      profile.education = item.code;
    }
  }
  for (const item of profileData.jobs) {
    if (item.code == snap.baseInfo.mainInfo.work) {
      profile.work = item.code;
    }
  }
  for (const item of profileData.locations) {
    if (item.code == snap.baseInfo.mainInfo.location) {
      profile.location = item.code;
    }
  }
  
  /** HOBBIES */
  profile.hobbies = [];
  for (const item of profileData.hobbies) {
    if (snap.hobbies?.includes(item.code)) {
      profile.hobbies.push(item.code);
    }
  }
  if (profile["hobbies"].length == 0) {
    delete profile["hobbies"];
  }

  if(snap.customHobbies) {
    profile.customHobbies = snap.customHobbies;
  }
  

  /** LANGUAGES */
  profile["languages"] = [];
  for (const item of profileData.languages) {
    if (snap.languages?.includes(item.code)) {
      profile.languages.push(item.code);
    }
  }
  if (profile["languages"].length == 0) {
    delete profile["languages"];
  }

  /** ADDITIONAL INFO */
  for (const item of profileData.smoking) {
    if (item.code == snap.smoking) {
      profile.smoking = item.code;
    }
  }
  for (const item of profileData.religions) {
    if (item.code == snap.religion) {
      profile.religion = item.code;
    }
  }
  
  const photosArr = [];
  for (const photo of snap.photos) {
    if (photo != "") {
      photosArr.push(photo);
    }
  }
  if (photosArr.length == 0) {
    delete profile.photos;
  } else {
    profile.photos = photosArr;
  }
  
  const photos: string[] = [];
  photos.length = 0;
  if (profile.profilePhoto) {
    photos.push(profile.profilePhoto);
  }
  if (profile.photos) {
    photos.push(...profile.photos);
  }
  if (profile.titlePhoto) {
    photos.push(profile.titlePhoto);
  }
  return {profile: profile, allPhotos: photos};
}

export async function loadProfileByUid(uid: string) {
  const profileRef = fbDB.ref("users/" + uid + "/profile");
  try {
    const snapshot = await profileRef.once("value");
    if (snapshot.val() !== null) {
      return await parseFullProfileFromDB(uid, snapshot.val());
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function parseBaseInfo(uid: string, snap: any) {
  const profile: {[key: string]: any}|null = {};
  profile["uid"] = uid;
  profile["name"] = snap.name;
  profile["bio"] = snap.bio;
  if (snap.profilePhoto != "") {
    profile["profilePhoto"] = snap.profilePhoto;
  }
  if (snap.titlePhoto != "") {
    profile["titlePhoto"] = snap.titlePhoto;
  }
  for (const item of profileData.education) {
    if (item.code == snap.mainInfo.education) {
      profile.education = item.code;
    }
  }
  for (const item of profileData.jobs) {
    if (item.code == snap.mainInfo.work) {
      profile.work = item.code;
    }
  }
  for (const item of profileData.locations) {
    if (item.code == snap.mainInfo.location) {
      profile.location = item.code;
    }
  }
  return profile;
}

export async function loadBaseInfoByUid(uid: string) {
  const profileRef = fbDB.ref("users/" + uid + "/profile/baseInfo");
  try {
    const snapshot = await profileRef.once("value");
    if (snapshot.val() !== null) {
      const profile = await parseBaseInfo(uid, snapshot.val());
      return {result: 'success', profile: profile};
    } else {
      return {result: 'fail'};
    }
  } catch (err) {
    console.log("ERROR from loadProfileByUid:");
    console.log(err);
    return {result: 'fail'};
  }
}


