
import { defineComponent, ref, computed } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonChip,
  IonIcon,
  IonLabel,
  IonFab,
  IonFabButton,
  IonTitle,
  IonCheckbox,
  IonSearchbar,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline, arrowUpOutline } from "ionicons/icons";
import jsonData from "@/assets/criteriaData.json";
import { myCriteria } from "@/components/criteria";

export default defineComponent({
  name: "Work",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonChip,
    IonCard,
    IonIcon,
    IonCardHeader,
    IonFab,
    IonFabButton,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonTitle,
    IonCheckbox,
    IonSearchbar,
  },
  props: {},
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const listOfJobs = ref(jsonData.jobs);
    listOfJobs.value.forEach((item) => {
      if (myCriteria.value.works) {
        if (myCriteria.value.works?.[item.code]) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
    });

    const searchQuery = ref("");
    const lang = localStorage.language == "gb" ? "en" : "sk";
    const filteredJobsList = computed(() => {
      const filtered = listOfJobs.value.filter((item) => {
        return (
          item[lang].toLowerCase().indexOf(searchQuery.value.toLowerCase()) !==
          -1
        );
      });
      return filtered.sort((a: any, b: any) => {
        if (a[lang] < b[lang]) {
          return -1;
        }
        if (a[lang] > b[lang]) {
          return 1;
        }
        return 0;
      });
    });

    const checkboxChanged = (event: any) => {
      const workCode: string = event.detail.value;
      if (!myCriteria.value.works) {
        myCriteria.value.works = {};
      }

      if (event.detail.checked == false) {
        delete myCriteria.value.works[workCode];
      } else if (event.detail.checked == true) {
        myCriteria.value.works[workCode] = true;
      }
    };

    const scrollToJob = (jobCode: string) => {
      document.getElementById(jobCode)?.scrollIntoView({behavior: "smooth"});
    };

    const scrollToTop = () => {
      document.querySelector("ion-card-header#header")?.scrollIntoView({behavior: "smooth"});
    };

    return {
      scrollToTop,
      arrowUpOutline,
      scrollToJob,
      myCriteria,
      filteredJobsList,
      searchQuery,
      closeModal,
      checkmarkOutline,
      checkboxChanged,
    };
  },
});
