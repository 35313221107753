
import { IonApp } from "@ionic/vue";
import { defineComponent } from "vue";
import { setDarkMode } from "@/settings/settings";

export default defineComponent({
  name: "App",
  components: {
    IonApp
  },
  setup() {
    setDarkMode();
  },
});
