import { ref } from "vue";

import { fbDB, fbStorage } from "@/firebase";
import { user } from "@/auth";
import { loadBaseInfoByUid } from "@/components/profile/show"

export const listOfChats = ref<({ [key: string]: object })[]>([]);
export const loadingListOfChats = ref<boolean>(true);

export async function loadChats() {
  listOfChats.value = [];
  loadingListOfChats.value = true;
  const myChatsRef = fbDB.ref(`users/${user.value.uid}/chats`)
    .orderByChild("lastMessage/timestamp");

  myChatsRef.off();

  myChatsRef.on('child_added', async (snap) => {
    const chatId: string | null = snap.key;
    const chatInfo = snap.val();
    if (chatId == null || chatInfo == null) {
      loadingListOfChats.value = false;
      return;
    }

    const { result, profile } = await loadBaseInfoByUid(chatInfo.partnerUid);
    if (result == 'fail') {
      console.log('failed to load contact profile');
      return;
    }

    listOfChats.value.unshift({
      ...{ chatId: chatId },
      ...chatInfo,
      ...{ partnerProfile: { ...profile } }
    });

    loadingListOfChats.value = false;
  });

  setTimeout(() => {
    loadingListOfChats.value = false;
  }, 2000);

  myChatsRef.on("child_changed", (snap: any) => {
    if (snap.key == null) {
      return;
    }
    let index = 0;
    for (const theChat of listOfChats.value) {
      if (theChat.chatId == snap.key) {
        break;
      }
      index += 1;
    }

    listOfChats.value[index]["lastMessage"] = snap.val().lastMessage;
  });

  myChatsRef.on("child_removed", (snap: any) => {
    for (let i = 0; i < listOfChats.value.length; i++) {
      const chatId: any = listOfChats.value[i].chatId;
      if (chatId == snap.key) {
        listOfChats.value.splice(i, 1);
      }
    }
  });
}


export const loadingTheChat = ref<boolean>(true);
export const blockedUser = ref<boolean>(false);
export const errorOccured = ref<boolean>(false);
export const listOfMessages = ref<Array<any>>([]);
export const allChatPhotos = ref<Array<any>>([]);
export const chatInfo = ref<{ [key: string]: any }>({});
export const contactInfo = ref<{ [key: string]: any }>({});

export const sendingMessage = ref<boolean>(false);
export const sendingMedia = ref<boolean>(false);
export async function sendTextMessage(textMessage: string) {
  if (textMessage.trim() == '' || blockedUser.value) {
    return;
  }
  const chatId = chatInfo.value.chatId;
  const partnerId = chatInfo.value.partnerUid;
  const newMessageKey = fbDB
    .ref()
    .child(`/chats/${chatId}/messages`)
    .push().key;
  const currentDate = new Date();
  const messageData = {
    timestamp: currentDate.getTime(),
    type: "text",
    text: textMessage.trim(),
    sender: user.value.uid,
  };

  const updates: any = {};
  updates[`/chats/${chatId}/messages/${newMessageKey}`] = messageData;
  updates[`/users/${user.value.uid}/chats/${chatId}/lastMessage`] = messageData;

  try {
    const blockedSnap = await fbDB
      .ref(`/users/${partnerId}/chats/${chatId}/blocked`)
      .once("value");
    const blocked = blockedSnap.val();

    if (blocked != true) {
      updates[`/users/${partnerId}/chats/${chatId}/lastMessage`] = { ...messageData, ...{ seen: false } };
    }

    await fbDB.ref().update(updates);
  } catch (error) {
    console.log(error);
  }
}

export const mediaUploadProgress = ref<boolean>(false);

export async function sendMedia(mediaData: Array<{ [key: string]: any }>) {
  if (blockedUser.value) {
    return;
  }
  mediaUploadProgress.value = true;
  const chatId = chatInfo.value.chatId;
  const partnerId = chatInfo.value.partnerUid;
  await Promise.all(
    mediaData.map(async (item) => {
      const newMessageKey = fbDB
        .ref()
        .child(`/chats/${chatId}/messages`)
        .push().key;

      const currentDate = new Date();
      const messageData = {
        timestamp: currentDate.getTime(),
        type: item.type,
        photoUrl: "",
        sender: user.value.uid,
      };
      const fileMetadata: any = {
        customMetadata: {}
      }

      fileMetadata.customMetadata[user.value.uid] = "true";
      fileMetadata.customMetadata[partnerId] = "true";

      try {
        const blockedSnap = await fbDB
          .ref(`/users/${partnerId}/chats/${chatId}/blocked`)
          .once("value");
        const blocked = blockedSnap.val();

        const snapshot = await fbStorage
          .ref(`messages/${chatId}/media/${newMessageKey}`)
          .put(item.data, fileMetadata);

        const url = await snapshot.ref.getDownloadURL();
        if (url != null) {
          messageData.photoUrl = url;
        }
        
        const updates: any = {};
        updates[`/chats/${chatId}/messages/${newMessageKey}`] = messageData;
        updates[`/chats/${chatId}/media/${newMessageKey}`] = { url: url, type: messageData.type, timestamp: messageData.timestamp };
        updates[`/users/${user.value.uid}/chats/${chatId}/lastMessage`] = messageData;
        if (blocked != true) {
          updates[`/users/${partnerId}/chats/${chatId}/lastMessage`] = { ...messageData, ...{ seen: false } };
        }

        await fbDB.ref().update(updates);
      } catch (err) {
        console.log(err);
      } 
    })
  );
  mediaUploadProgress.value = false;
}