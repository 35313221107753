<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="ios">
        <ion-buttons slot="end">
          <ion-button @click="closeModal()" color="primary">
            {{ $t("buttons.done") }}
          </ion-button>
        </ion-buttons>
        <ion-title>
          <img src="/assets/logo.png" style="height: 30px" />
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card style="margin: 0px; border-radius: 0px; box-shadow: none">
        <ion-card-header>
          <ion-card-subtitle>{{
            $t("criteria.languages.languages")
          }}</ion-card-subtitle>
          <ion-card-title>{{
            $t("criteria.languages.question")
          }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div v-for="(lang, index) in languagesList" :key="index">
            <ion-item v-if="lang.code != 'xx'">
              <ion-checkbox
                id="lang.code"
                color="primary"
                slot="start"
                :value="lang.code"
                @update:modelValue="lang.isChecked = $event"
                :modelValue="lang.isChecked"
                @ionChange="checkboxChanged($event)"
              ></ion-checkbox>
              <ion-label>{{
                $t("profile.languages.options." + lang.code)
              }}</ion-label>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonTitle,
  IonCheckbox,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline } from "ionicons/icons";
import { myCriteria } from "@/components/criteria";
import jsonData from "@/assets/criteriaData.json";

export default defineComponent({
  name: "Languages",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonTitle,
    IonCheckbox,
  },
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const languagesList = ref(jsonData.languages);

    languagesList.value.forEach((item) => {
      if (myCriteria.value.languages) {
        if (myCriteria.value.languages?.[item.code]) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
    });

    /** Add or remove language to/from criteria directory. */
    const checkboxChanged = (event: any) => {
      const langCode: string = event.detail.value;
      if (!myCriteria.value.languages) {
        myCriteria.value.languages = {};
      }

      if (event.detail.checked == false) {
        delete myCriteria.value.languages[langCode];
      } else if (event.detail.checked == true) {
        myCriteria.value.languages[langCode] = true;
      }
    };

    return {
      checkboxChanged,
      languagesList,
      closeModal,
      checkmarkOutline,
    };
  },
});
</script>

<style scoped>
ion-item {
  --background: inherit;
}
</style>