<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar style="text-align: center">
        <ion-buttons slot="start">
          <ion-button disabled>
            <ion-icon></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="primary">
          <ion-button @click="closeModal()">
            <ion-icon
              slot="icon-only"
              style="color: grey"
              :icon="closeOutline"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
        <img src="/assets/logo.png" style="height: 50px" />
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <h1 style="color: black">{{ $t("titlePage.registration") }}</h1>
      <v-form @submit="continueRegistration">
        <ion-item lines="none" class="fieldItem">
          <ion-icon
            :icon="personOutline"
            slot="start"
            style="color: #999"
          ></ion-icon>
          <v-field
            name="name"
            v-slot="{ field }"
            rules="required|max:50|alpha"
            :validateOnInput="aggressiveValidation.name"
            :validateOnBlur="false"
          >
            <input
              @change="enableAggressiveValidation('name')"
              :value="field.value"
              name="name"
              class="inputField"
              v-bind="field"
              :placeholder="$t('titlePage.name')"
            />
          </v-field>
        </ion-item>
        <v-error-message name="name" class="error" />

        <ion-item lines="none" class="fieldItem">
          <ion-icon
            :icon="mailOutline"
            slot="start"
            style="color: #999"
          ></ion-icon>
          <v-field
            name="email"
            v-slot="{ field }"
            rules="required|email|max:50"
            :validateOnInput="aggressiveValidation.email"
            :validateOnBlur="false"
          >
            <input
              @change="enableAggressiveValidation('email')"
              :value="field.value"
              name="email"
              class="inputField"
              v-bind="field"
              placeholder="E-mail"
              type="email"
            />
          </v-field>
        </ion-item>
        <v-error-message name="email" class="error" />

        <ion-item lines="none" class="fieldItem">
          <ion-icon
            :icon="lockClosedOutline"
            slot="start"
            style="color: #999"
          ></ion-icon>
          <v-field
            name="password"
            type="password"
            v-slot="{ field }"
            rules="required|min:8|max:50"
            :validateOnInput="aggressiveValidation.password"
            :validateOnBlur="false"
          >
            <input
              @change="enableAggressiveValidation('password')"
              name="password"
              v-bind="field"
              class="inputField"
              :placeholder="$t('titlePage.password')"
              :type="showPassword ? 'text' : 'password'"
            />
          </v-field>
          <ion-icon
            @click="showPassword = !showPassword"
            :icon="showPassword ? eyeOutline : eyeOffOutline"
            slot="end"
            style="cursor: pointer; color: #999"
          ></ion-icon>
        </ion-item>
        <v-error-message name="password" class="error" />

        <ion-item lines="none" class="fieldItem">
          <ion-icon
            :icon="giftOutline"
            slot="start"
            style="color: #999"
          ></ion-icon>
          <v-field
            name="year"
            v-slot="{ field }"
            rules="required"
            :validateOnInput="aggressiveValidation.year"
            :validateOnBlur="false"
          >
            <select
              @change="enableAggressiveValidation('year')"
              required
              class="inputField"
              style="cursor: pointer"
              name="year"
              v-bind="field"
            >
              <option value="" hidden selected style="color: red">
                {{ $t("titlePage.ageSelect") }}
              </option>
              <option :value="2021 - n - 18" v-for="n in 70" :key="n">
                {{ 2021 - n - 18 }}
              </option>
            </select>
          </v-field>
        </ion-item>
        <v-error-message name="year" class="error" />

        <div style="margin-top: 5px">
          <div style="width: 50%; display: inline-block">
            <ion-button
              expand="block"
              shape="round"
              type="button"
              @click="changeSex('man')"
              :fill="sex == 'man' ? 'solid' : 'outline'"
              :class="[sex == 'man' ? 'selectedSex' : 'notSelectedSex']"
            >
              {{ $t("titlePage.man") }}
            </ion-button>
          </div>
          <div style="width: 50%; display: inline-block">
            <ion-button
              expand="block"
              shape="round"
              type="button"
              @click="changeSex('woman')"
              :fill="sex == 'woman' ? 'solid' : 'outline'"
              class="sex"
              :class="[sex == 'woman' ? 'selectedSex' : 'notSelectedSex']"
            >
              {{ $t("titlePage.woman") }}
            </ion-button>
          </div>
        </div>
        <p v-if="sexNotSelected" class="error">
          {{ $t("titlePage.selectSex") }}
        </p>
        <div style="text-align:center">
          <!-- <ion-checkbox style="--background: #eee" color="dark" mode="md"> </ion-checkbox> -->
           <i18n-t
              keypath="titlePage.registrationStatement"
              tag="small"
              style="color: #bbb; margin"
            >
              <template v-slot:privacy>
                <a
                  style="color: inherit"
                  target="_blank"
                  href="https://www.laska.club/zasady-ochrany-osobnych-udajov"
                  >{{ $t("titlePage.privacyPolicy") }}</a
                >
              </template>
              <template v-slot:terms>
                <a
                  style="color: inherit"
                  target="_blank"
                  href="https://www.laska.club/zasady-ochrany-osobnych-udajov"
                  >{{ $t("titlePage.termsOfService") }}</a
                >
              </template>
            </i18n-t>
        </div>
        <ion-button
          mode="ios"
          type="submit"
          expand="block"
          shape="round"
          color="primary"
          style="margin: 15px 0px; height: 3rem"
          >{{ $t("buttons.continue") }}</ion-button
        >
      </v-form>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonIcon,
  IonButtons,
  IonItem,
  IonButton,
  modalController,
  loadingController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import {
  personOutline,
  closeOutline,
  mailOutline,
  eyeOffOutline,
  eyeOutline,
  lockClosedOutline,
  giftOutline,
  logoGoogle,
  logoApple,
  logoFacebook,
} from "ionicons/icons";
import router from "@/router";

import { signupClassic } from "@/auth";
import { openToast } from "@/general/toasts";

import * as V from "vee-validate/dist/vee-validate";
import { defineRule } from "vee-validate/dist/vee-validate";
import { required, email, min, max, alpha } from "@vee-validate/rules";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "RegistrationModal",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonIcon,
    IonButtons,
    IonItem,
    IonButton,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });

    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    let loadingSpinner: HTMLIonLoadingElement | undefined;
    const loadingSpinnerOpen = async () => {
      loadingSpinner = await loadingController.create({
        cssClass: "my-custom-class",
        translucent: true,
        message: t("titlePage.registrationInProgress"),
      });

      await loadingSpinner.present();
    };

    defineRule("required", required);
    defineRule("email", email);
    defineRule("min", min);
    defineRule("max", max);
    defineRule("alpha", alpha);

    const aggressiveValidation = ref<{ [key: string]: boolean }>({
      name: false,
      email: false,
      password: false,
      year: false,
    });
    const enableAggressiveValidation = (fieldId: string) => {
      aggressiveValidation.value[fieldId] = true;
    };

    const showPassword = ref(false);
    const sex = ref<string>("");
    const sexNotSelected = ref<boolean>(false);
    const changeSex = (sexSelected: string) => {
      sexNotSelected.value = false;
      sex.value = sexSelected;
    };

    const continueRegistration = async (values: any, actions: any) => {
      if (sex.value == "") {
        sexNotSelected.value = true;
        return;
      }
      loadingSpinnerOpen();

      values["sex"] = sex.value;
      values["name"] =
        values["name"][0].toUpperCase() + values["name"].slice(1).toLowerCase();

      const { result, code } = await signupClassic(values);

      if (result == "success") {
        closeModal();
        loadingSpinner?.dismiss();
        router.replace({ name: "registrationCriteria" });
      } else if (result == "authFail") {
        if (code == "auth/email-already-in-use") {
          actions.setFieldError("email", t("authValidation.emailAlreadyInUse"));
        } else if (code == "auth/weak-password") {
          actions.setFieldError("password", t("authValidation.weakPassword"));
        } else if (code == "auth/invalid-email") {
          actions.setFieldError("email", t("authValidation.invalidEmail"));
        } else if (code == "auth/operation-not-allowed") {
          console.log("Email auth not allowed on server side.");
        } else if (code == "auth/network-request-failed") {
          openToast("networkConnectionFailed", false);
        } else {
          openToast("registrationFailedUnknown", false);
          console.log(code);
        }
        loadingSpinner?.dismiss();
      } else if (result == "dbFail") {
        closeModal();
        loadingSpinner?.dismiss();
        router.replace("/registration/base");
      }
      loadingSpinner?.dismiss();
    };

    return {
      aggressiveValidation,
      enableAggressiveValidation,
      sexNotSelected,
      continueRegistration,
      required,
      email,
      min,
      max,
      sex,
      showPassword,
      changeSex,
      closeModal,
      // ICONS
      personOutline,
      closeOutline,
      mailOutline,
      eyeOffOutline,
      eyeOutline,
      lockClosedOutline,
      giftOutline,
      logoGoogle,
      logoApple,
      logoFacebook,
    };
  },
});
</script>

<style scoped>
h6 {
  width: 90%;
  text-align: center;
  border-bottom: 1px solid #aaa;
  line-height: 0.1em;
  margin: auto;
  color: #aaa;
}

h6 span {
  background: #fff;
  padding: 0 10px;
}

.error {
  display: block;
  margin: 5px 1.5rem;
  font-weight: light;
  font-size: smaller;
  color: rgb(228, 2, 2);
}

ion-item.fieldItem {
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-top: 5px;
  height: 3rem;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

ion-item.fieldItem:hover {
  border-color: #808080;
}

ion-item {
  --ion-item-background: white;
  --ion-text-color: black;
}

.inputField {
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
}

ion-content {
  --background: white !important;
}

ion-toolbar {
  --background: white !important;
}

select:invalid {
  color: #808080;
}

option {
  color: black;
}

ion-button.selectedSex {
  height: 3rem;
  --background: #808080 !important;
  --background-hover: #ccc !important;
  --background-focused: #ccc !important;
  --background-activated: #ccc !important;
  color: white;
  text-transform: capitalize;
}

ion-button.notSelectedSex {
  height: 3rem;
  color: #808080;
  --border-color: #ccc;
  --background-hover: #ccc !important;
  --background-focused: #ccc !important;
  --background-activated: #ccc !important;
  --border-width: 1px;
  text-transform: capitalize;
}
</style>