<template>
  <ion-app>
    <router-view/>
  </ion-app>
</template>

<script lang="ts">
import { IonApp } from "@ionic/vue";
import { defineComponent } from "vue";
import { setDarkMode } from "@/settings/settings";

export default defineComponent({
  name: "App",
  components: {
    IonApp
  },
  setup() {
    setDarkMode();
  },
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

div.firebase-emulator-warning {
  display: none;
}
</style>