import { ref } from "vue";

import { fbDB } from "@/firebase";
import { user } from "@/auth";
import {loadBaseInfoByUid} from "@/components/profile/show"

export const listOfLikes = ref<{[key: string]: any}>({});
export const loadingListOfLikes = ref<boolean>(true);


export async function loadLikes() {
  loadingListOfLikes.value = true;
  listOfLikes.value = {};

  const likesFromRef = fbDB.ref(`users/${user.value.uid}/likesFrom`);
  likesFromRef.on('child_added', async (snap) => {
    const partnerUid: string|null = snap.key;
    if(partnerUid == null){
      return;
    }

    const { result, profile } = await loadBaseInfoByUid(partnerUid);
    if (result == 'fail' || !profile) {
      console.log("contact profile loading failed");
      // error occured 
      return;
    }

    listOfLikes.value[partnerUid] = profile; 
  });
  
  likesFromRef.on('child_removed', async (snap) => {
    const partnerUid: string|null = snap.key;
    if(partnerUid == null){
      return;
    }
    delete listOfLikes.value[partnerUid];
  });

  setTimeout(() => {
    loadingListOfLikes.value = false;
  }, 1500);
}


